/**
 * @license
 * Copyright 2016 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

// Former goog.module ID: Blockly.constants

/**
 * The language-neutral ID given to the collapsed input.
 */
export const COLLAPSED_INPUT_NAME = '_TEMP_COLLAPSED_INPUT';

/**
 * The language-neutral ID given to the collapsed field.
 */
export const COLLAPSED_FIELD_NAME = '_TEMP_COLLAPSED_FIELD';
